import React from "react";
import Layout from "../../components/layout";
import OAuthAndOpenid from "../../components/views/solutions/oauth-and-openid";

const OAuthAndOpenIdPage = () => {
  return (
    <Layout>
      <OAuthAndOpenid />
    </Layout>
  );
};

export default OAuthAndOpenIdPage;
